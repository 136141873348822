<template>
  <span>
    <template v-for="c in renderedNote" :key="c.id">
      <span v-if="c.type === 1">{{ c.content }}</span>
      <a v-else href="javascript:void(0);" @click="$emit('open', c.id)">{{
        c.content
      }}</a>
    </template>
  </span>
</template>

<script>
import * as DOMPurify from "dompurify";
export default {
  name: "Note",
  props: {
    note: String,
  },
  data() {
    return {
      ids: [],
    };
  },
  computed: {
    renderedNote: function () {
      let cleanNote = DOMPurify.sanitize(this.note, {
        USE_PROFILES: { html: true },
      });
      let nc = cleanNote.split(/(#\d+)/gm);
      let noteComponents = [];
      nc.forEach((c) => {
        if ((/(#\d+)/gm.exec(c) || []).length > 0) {
          noteComponents.push({
            type: 2,
            content: c,
            id: c.replace(/#/g, ""),
          });
        } else {
          noteComponents.push({
            type: 1,
            content: c,
          });
        }
      });
      return noteComponents;
    },
  },
};
</script>

<style scoped></style>

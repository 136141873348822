<template>
  <div class="row">
    <div class="col-xl-4 col-md-6 col-sm-12">
      <div class="card widget widget-list">
        <div class="card-header">
          <h5 class="card-title">Vehicle Details</h5>
        </div>
        <!-- Vehicle Details -->
        <div class="card-body">
          <ul class="widget-list-content list-unstyled">
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.processing.vehicle-registration") }}
                </a>
                <span class="font-xs">
                  {{ claim.vehicle.plate }}
                </span>
              </span>
            </li>
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.new.vehicle_make") }}
                </a>
                <span class="font-xs">
                  {{ make }}
                </span>
              </span>
            </li>
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.new.vehicle_model") }}
                </a>
                <span class="font-xs">
                  {{ model }}
                </span>
              </span>
            </li>
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.new.vehicle_colour") }}
                </a>
                <span class="font-xs">
                  {{ colour }}
                </span>
              </span>
            </li>
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.new.fuel_type") }}
                </a>
                <span class="font-xs">
                  {{ claim.fuelType }}
                </span>
              </span>
            </li>
            <li class="widget-list-item widget-list-item-green">
              <span class="widget-list-item-description">
                <a href="#" class="widget-list-item-description-title">
                  {{ $t("claims.new.event_on_site") }}
                </a>
                <span class="font-xs">
                  {{ claim.eventCount }}
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="claim.lastEvent" class="col-xl-4 col-md-6 col-sm-12">
      <div class="card">
        <event-image
          @click="openEventImage(claim.lastEvent)"
          key="vehicleImage"
          class="card-img-top"
          :filename="claim.lastEvent.image"
          :site_id="claim.site.id"
          :viewer="false"
          :id="'eventimage-' + claim.lastEvent.id"
          :storage="claim.lastEvent.storage_account"
        ></event-image>
        <div class="card-body">
          <h5 class="card-title">Last Event Seen</h5>
          <p class="card-text">
            Entered at
            <timestamp
              :time="claim.lastEvent.created_at"
              format="HH:mm:ss"
            ></timestamp>
            on
            <timestamp
              :time="claim.lastEvent.created_at"
              format="dd/MM/yyyy"
            ></timestamp>
          </p>
        </div>
      </div>
    </div>

    <!-- Claims Details -->
    <div class="col-xl-4 col-md-6 col-sm-12">
      <!-- <div class="card widget widget-info-navigation">
              <div class="card-body">
                  <div class="widget-info-navigation-container">
                      <div class="widget-info-navigation-content">
                        <span> £{{total_outstanding}} </span>
                      </div>
                  </div>
              </div>
          </div> -->
      <div class="card widget widget-info-navigation">
        <div class="card-body">
          <div class="widget-info-navigation-container">
            <div class="widget-info-navigation-content">
              <div class="flex-center space-between">
                <span class="text-muted">Drive Off's</span>
                <span v-if="driveoff.amount > 0" class="text-muted">
                  {{ driveoff.amount }} claims
                </span>
              </div>
              <span class="text-primary fw-bolder fs-2">
                £ {{ driveoff.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card widget widget-info-navigation">
        <div class="card-body">
          <div class="widget-info-navigation-container">
            <div class="widget-info-navigation-content">
              <div class="flex-center space-between">
                <span class="text-muted">No Means of Payment's</span>
                <span v-if="nmop.amount > 0" class="text-muted">
                  {{ nmop.amount }} claims
                </span>
              </div>
              <span class="text-primary fw-bolder fs-2">
                £ {{ nmop.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card widget widget-info-navigation">
        <div class="card-body">
          <div class="widget-info-navigation-container">
            <div class="widget-info-navigation-content">
              <div class="flex-center space-between">
                <span class="text-muted">Parking Charges</span>
                <span v-if="parking.amount > 0" class="text-muted">
                  {{ parking.amount }} claims
                </span>
              </div>
              <span class="text-primary fw-bolder fs-2">
                £ {{ parking.value }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Vehicle Image with mmc -->
    <div class="col-xl-4 col-md-6 col-sm-12">
      <div v-if="vehicleImageURL !== null" class="card">
        <!-- <asset-image :filename="vehicleImage.filename" :endpoint="vehicleImage.endpoint" :site_id="claim.site_id" :id="'img-' + vehicleImage.filename" /> -->
        <asset-image
          :filename="vehicleImageURL"
          :claim_id="claim.id"
          :client_id="client_id"
          :id="'img-' + vehicleImageURL"
        ></asset-image>
        <!-- <event-image @click="openEventImage(claim.lastEvent)" key='vehicleImage' class="card-img-top" :filename="claim.lastEvent.image" :site_id="claim.site.id" :id="'eventimage-' + claim.lastEvent.id"></event-image> -->
        <div class="card-body">
          <p class="card-text">Vehicle Image</p>
        </div>
      </div>
      <div class="card">
        <div
          id="exampleVehiclesCarousel"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              v-for="(example, index) in examples"
              :key="'img-' + example"
              class="carousel-item"
              :class="{ active: index === 0 }"
            >
              <img :src="example" class="d-block w-100" />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#exampleVehiclesCarousel"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#exampleVehiclesCarousel"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="card-body">
          <p class="card-text">
            Example images of the Make, Model and Colour for this claim.
          </p>
        </div>
      </div>
    </div>
  </div>

  <teleport to="body">
    <div
      class="modal fade"
      id="vehicleImageModal"
      tabindex="-1"
      style="display: none; padding-right: 17px"
      aria-modal="true"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        style="height: 80vh; width: 75%; max-width: 75%"
      >
        <event-image
          v-if="currentEvent !== null"
          style="width: 100%; height: auto"
          :thumb="false"
          :filename="currentEvent.image"
          :site_id="currentEvent.site_id"
          :id="'eventimage-' + currentEvent.id"
          :storage="currentEvent.storage_account"
        />
        <p class="closePopup">
          Press <code>ESC</code> or click outside of the image to close this
          popup!
        </p>
      </div>
    </div>
  </teleport>
</template>

<script>
import EventImage from "../../../../../components/EventImage.vue";
import Timestamp from "../../../../../components/Timestamp.vue";
import ClaimService from "../../../../../services/claims.service";
import axios from "axios";
import ClaimAssetImage from "../../../../../components/ClaimAssetImage.vue";
export default {
  name: "ClaimVehicle",
  components: {
    EventImage,
    Timestamp,
    ClaimAssetImage,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {
    return {
      currentEvent: null,
      outstanding: 0.0,
      driveoff: {
        amount: 0,
        value: 0.0,
      },
      nmop: {
        amount: 0,
        value: 0.0,
      },
      parking: {
        amount: 0,
        value: 0.0,
      },
      examples: [],
      vehicleImageURL: null,
    };
  },
  mounted() {
    console.log(this.claim);
    this.loadVehicle();
    this.findExampleImages();
    this.getVehicleImage();
  },
  methods: {
    loadVehicle() {
      ClaimService.getClaimVehicle(
        this.client_id,
        this.claim.id,
        this.claim.vehicle_id
      )
        .then((response) => {
          //all outstanding claims for that vehicle
          this.outstanding = response.data.claims;
          this.driveoff.amount = response.data.counts.driveoff;
          this.driveoff.value = parseFloat(
            response.data.values.driveoff
          ).toFixed(2);
          this.nmop.amount = response.data.counts.nmop;
          this.nmop.value = parseFloat(response.data.values.nmop).toFixed(2);
          this.parking.amount = response.data.counts.parking;
          this.parking.value = parseFloat(response.data.values.parking).toFixed(
            2
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findExampleImages() {
      axios
        .get(
          `https://api.bing.microsoft.com/v7.0/images/search?q=${encodeURIComponent(
            `${this.make} ${this.model} ${this.colour}`
          )}`,
          {
            headers: {
              "Ocp-Apim-Subscription-Key": "16d737058c5c4dbe88213cf1cad53f0c",
            },
          }
        )
        .then((response) => {
          response.data.value.forEach((entry) => {
            this.examples.push(entry.thumbnailUrl);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openEventImage(event) {
      this.currentEvent = event;
      $("#vehicleImageModal").modal("toggle");
    },
    getVehicleImage() {
      let imageArray = this.claim.assets.filter((asset) => {
        return asset.type === "vehicle_image";
      });
      if (imageArray.length > 0) {
        this.vehicleImageURL = imageArray[0].filename;
      }
    },
  },
  computed: {
    make: function () {
      if (this.claim.vehicle_make === null) return this.claim.make;
      if (this.claim.vehicle_make.length < 1) return this.claim.make;
      return this.claim.vehicle_make;
    },
    model: function () {
      if (this.claim.vehicle_model === null) return this.claim.model;
      if (this.claim.vehicle_model.length < 1) return this.claim.model;
      return this.claim.vehicle_model;
    },
    colour: function () {
      if (this.claim.vehicle_colour === null) return this.claim.colour;
      if (this.claim.vehicle_colour.length < 1) return this.claim.colour;
      return this.claim.vehicle_colour;
    },
    // vehicleImage: function(){
    //   if(this.claim.events.length > 0){
    //     return {filename: this.claim.events[0].image, endpoint: 'event/thumbnail'}
    //   }
    //   let image = this.claim.assets.filter((a) => {
    //     return a.type === 'vehicle_image';
    //   })[0];
    //   if(image === undefined){
    //     return {filename: image.filename, endpoint: 'evidence/'}
    //   }
    //   return null;
    // },
    total_outstanding: function () {
      // return (this.driveoff.value + this.nmop.value + this.parking.value).toFixed(2);
    },
  },
};
</script>

<style scoped>
.widget-list-item-description-title {
  color: #919ba7 !important;
}

.text-muted {
  font-size: 16px;
}
</style>

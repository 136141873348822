<template>
  <div class="card widget widget-chat">
    <div class="card-header">
      <h5 class="card-title">{{ $t("event.notes").toUpperCase() }}</h5>
    </div>
    <div class="widget-chat-messages-container" id="claimNoteContainer">
      <!-- <span v-if="claim.notes.length < 1" class="no-data-container"> No notes added </span> -->
      <div
        v-for="note in claim.notes"
        :key="note.id + 'note'"
        class="widget-chat-message-item space-between"
        :class="{ own: note.user_id === $store.state.auth.user.id }"
      >
        <!-- Note content -->
        <div class="widget-chat-message-item-content">
          <div class="widget-chat-message-item-text">
            <note :note="note.note" @open="openTicket"></note>
          </div>
          <span class="text-muted m-l-sm" style="font-size: 11px"
            >Created
            <timestamp :time="note.created_at" format="HH:mm"></timestamp> on
            <timestamp :time="note.created_at" format="dd/MM/yyyy"></timestamp>
            by {{ note.user }}
          </span>
        </div>
      </div>
    </div>
    <div class="widget-chat-compose">
      <input
        @keydown.enter="createNote"
        v-model="newNote"
        class="widget-chat-compose-input"
        type="text"
        placeholder="Add new note..."
      />
      <button
        @click="createNote"
        class="widget-chat-compose-send-btn text-white"
        :class="{ disabled: sending }"
      >
        <span
          v-if="sending"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span
        ><i class="material-icons" v-else>arrow_forward_ios</i>
      </button>
    </div>

    <!-- View Ticket Modal -->
    <div
      class="modal fade"
      :id="'ticketModal-' + claim.reference"
      tabindex="-1"
      style="display: none"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-lg modal-lg modal-dialog-centered">
        <div class="modal-content p-h-xxl p-v-xxl">
          <div class="modal-title flex-center">
            <h3 class="m-r-sm">VIEW TICKET</h3>
          </div>

          <div
            v-if="ticket.id"
            class="modal-body"
            style="max-height: 75vh; overflow-y: scroll"
          >
            <p>
              <strong>Provided Reference: </strong
              >{{ ticket.custom_fields.cf_customer_reference_number || "None" }}
            </p>

            <div class="m-b-sm">
              <h5 class="mailbox-open-title">
                {{ ticket.subject }}
              </h5>
              <div class="tx-inverse" v-html="ticket.description"></div>
            </div>

            <hr />

            <div v-for="msg in ticket.conversations" class="m-b-sm">
              <div class="mailbox-open-author">
                <div class="mailbox-open-author-info">
                  <span
                    class="mailbox-open-author-info-email d-block text-white"
                    >{{ msg.from_email }}</span
                  >
                  <span class="mailbox-open-author-info-to"
                    >To
                    <span class="badge badge-info align-self-center">{{
                      msg.to_emails.join(", ")
                    }}</span></span
                  >
                </div>
              </div>
              <div class="mailbox-open-content-email">
                <div
                  class="tx-inverse"
                  v-html="msg.body.split('<blockquote></blockquote>')[0]"
                ></div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClaimService from "../../../../../services/claims.service";
import Timestamp from "../../../../../components/Timestamp.vue";
import Note from "./Note.vue";
import axios from "axios";
import authHeader from "../../../../../services/auth-header";

export default {
  name: "ClaimNotes",
  components: {
    Note,
    Timestamp,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {
    return {
      newNote: "",
      sending: false,
      ticket: {},
    };
  },
  methods: {
    deleteNote(note) {
      ClaimService.deleteNote(this.client_id, this.claim.id, note.id)
        .then((response) => {
          this.$emit("delete", note);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createNote() {
      if (this.sending) return;
      this.sending = true;
      ClaimService.createNote(this.client_id, this.claim.id, this.newNote)
        .then((response) => {
          this.$emit("create", {
            claim_id: response.data.claim_id,
            created_at: response.data.created_at,
            note: response.data.note,
            user: response.data.user.email,
            user_id: response.data.user.id,
            id: response.data.id,
          });
          this.sending = false;
          this.newNote = "";
        })
        .catch((error) => {
          console.error(error);
          this.sending = false;
        });
    },
    openTicket(id) {
      if (!this.$can("claims-view-ticket")) {
        this.$error("You do not have permission to view tickets.");
        return;
      }
      console.log("Open Ticket: ", id);
      axios
        .get(`https://api.varsanpr.com/api/misc/freshdesk/${id}`, {
          headers: authHeader(),
        })
        .then((response) => {
          this.ticket = response.data.ticket;
          $("#ticketModal-" + this.claim.reference).modal("toggle");
        })
        .catch((error) => {
          this.$error("Failed to load ticket.", error);
        });
    },
  },
};
</script>

<style scoped>
.widget-chat .widget-chat-messages-container .widget-chat-message-item {
  align-items: center;
}

.delete-button {
  font-size: 25px;
}

.delete-button:hover {
  color: red;
  cursor: pointer;
}
</style>

<template>
  <!-- <h6> ClaimDetails Component </h6> -->
  <div class="row" v-if="claim.reference !== undefined">
    <!-- Claim Details -->
    <div class="col-xl-6">
      <div class="card widget widget-list">
        <div class="card-header">
          <h5 class="card-title">{{ $t("manage_evidence.claim_details") }}</h5>
        </div>
        <div class="card-body">
          <!-- <ul class="widget-list-content list-unstyled">
                  <li class="widget-list-item widget-list-item-green">
                      <span class="widget-list-item-icon"><i class="material-icons-outlined">place</i></span>
                      <span class="widget-list-item-description">
                          <a href="javascript:void(0);" class="widget-list-item-description-title">
                              {{claim.site.reference}} {{claim.site.name}}
                          </a>
                          <span class="widget-list-item-description-subtitle">
                              
                          </span>
                      </span>
                  </li>
                  <li class="widget-list-item widget-list-item-blue">
                      <span class="widget-list-item-icon"><i class="material-icons-outlined">verified_user</i></span>
                      <span class="widget-list-item-description">
                          <a href="javascript:void(0);" class="widget-list-item-description-title">
                              {{claim.user}}
                          </a>
                          <span class="widget-list-item-description-subtitle">
                              
                          </span>
                      </span>
                  </li>
                  <li class="widget-list-item widget-list-item-purple">
                      <span class="widget-list-item-icon"><i class="material-icons-outlined">watch_later</i></span>
                      <span class="widget-list-item-description">
                          <a href="javascript:void(0);" class="widget-list-item-description-title">
                              <timestamp :time="incidentTimestamp" format="dd/MM/yyyy HH:mm"></timestamp>
                          </a>
                          <span class="widget-list-item-description-subtitle">
                              
                          </span>
                      </span>
                  </li>
                  <li class="widget-list-item widget-list-item-yellow">
                      <span class="widget-list-item-icon"><i class="material-icons-outlined">info</i></span>
                      <span class="widget-list-item-description">
                          <a href="javascript:void(0);" class="widget-list-item-description-title">
                              {{claimType}}
                          </a>
                          <span class="widget-list-item-description-subtitle">
                              
                          </span>
                      </span>
                  </li>
              </ul> -->

          <div class="main-container">
            <div class="sub-container widget-list-content">
              <div class="widget-list-item widget-list-item-red">
                <span class="widget-list-item-icon"
                  ><i class="material-icons-outlined">info</i></span
                >
                <span class="widget-list-item-description">{{
                  $t("labels.claim_type")
                }}</span>
              </div>

              <div class="widget-list-item widget-list-item-blue">
                <span class="widget-list-item-icon"
                  ><i class="material-icons-outlined">place</i></span
                >
                <span class="widget-list-item-description">{{
                  $t("claims.new.site")
                }}</span>
              </div>

              <div class="widget-list-item widget-list-item-green">
                <span class="widget-list-item-icon"
                  ><i class="material-icons-outlined">verified_user</i></span
                >
                <span class="widget-list-item-description">{{
                  $t("labels.reported_by")
                }}</span>
              </div>

              <div class="widget-list-item widget-list-item-yellow">
                <span class="widget-list-item-icon"
                  ><i class="material-icons-outlined">watch_later</i></span
                >
                <span class="widget-list-item-description">{{
                  $t("manage_evidence.labels.timestamp")
                }}</span>
              </div>
            </div>
            <div class="sub-container right widget-list-content">
              <span>{{ claimType }}</span>
              <a
                href="javascript:void(0);"
                class="widget-list-item-description-title"
                style="font-size: 17px"
                @click="$router.push(`/sites/${claim.site.id}`)"
              >
                {{ claim.site.reference }} {{ claim.site.name }}
              </a>
              <!-- <span>{{claim.site.name}}</span> -->
              <span>{{ claim.user }}</span>
              <span>
                <timestamp :time="incidentTimestamp" format="dd/MM/yyyy HH:mm">
                </timestamp>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <!-- Site Comments -->
      <div class="card" v-if="claim.type !== 'parking'">
        <div class="card-body">
          <h5 class="card-title">Site Comments</h5>
          <p class="card-text">
            {{
              claim.comments ||
              "No comments were provided by the site on submission."
            }}
          </p>
        </div>
      </div>

      <!-- TODO On-site facilities  -->
      <div class="card" v-if="claim.site.brands.length > 0">
        <div class="card-body">
          <h5 class="card-title">On-Site Facilities</h5>
          <div class="row" id="claim-details-brands">
            <brand-image
              v-for="brand in claim.site.brands"
              :key="'brand-' + brand.id"
              class="claim-details brand-logo p-v-sm p-h-sm"
              :title="brand.name"
              :filename="brand.filename"
            ></brand-image>
          </div>
        </div>
      </div>

      <!-- Cnacellation Reason -->
      <div class="card" v-if="claim.status === 'Cancelled'">
        <div class="card-body">
          <h5 class="card-title">Cancellation Reason</h5>
          <p class="card-text">
            {{
              claim.cancellation_reason ||
              "No reason was provided for the cancellation of this claim."
            }}
          </p>
        </div>
      </div>

      <!-- Claim Warnings -->
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Claim Warnings</h5>
          <p class="card-text">
            {{
              claim.warnings.length < 1
                ? "No Warnings"
                : `${claim.warnings.length} Warnings!`
            }}
          </p>
          <div v-if="claim.warnings.length > 1">
            <!-- TODO - Check if its working or not -->
            <p v-for="warning in claim.warnings" :key="'warning-' + warning.id">
              {{ claim.warnings.indexOf(warning) + 1 + "." }} {{ warning }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandImage from "./BrandImage.vue";
import Timestamp from "../../../../components/Timestamp.vue";
export default {
  name: "ClaimDetails",
  components: {
    BrandImage,
    Timestamp,
  },
  props: {
    claim: Object,
    incidentTimestamp: Number,
  },
  data() {
    return {
      // claim: {
      //   warnings: [
      //     'Warning number 1',
      //     'Warning number 2'
      //   ]
      // }
    };
  },
  computed: {
    claimType: function () {
      switch (this.claim.type) {
        case "driveoff":
          return "Drive Off";
        case "escalated":
          return "Drive Off (No Means of Payment)";
        case "nmop":
          return "No Means of Payment";
        case "parking":
          return "Parking";
        case "cnaf":
          return "Customer Not at Fault";
        case "cnafe":
          return "Drive Off (Customer Not at Fault)";
      }
    },
    validBrands: function () {
      if (this.claim.site === undefined) return [];
      if (this.claim.site.brands === undefined) return [];
      return this.claim.site.brands.filter((brand) => {
        return brand.filename !== undefined && brand.filename !== null;
      });
    },
  },
};
</script>

<style scoped>
.brand-logo {
  height: auto;
  width: 110px;
  border-radius: 50%;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
}

.sub-container {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.sub-container.right {
  flex-grow: 1;
}

.sub-container span,
.sub-container a {
  padding: 10px 0;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
}

.widget-list .widget-list-content .widget-list-item .widget-list-item-icon {
  width: 35px;
  height: 35px;
}

.widget-list .widget-list-content .widget-list-item .widget-list-item-icon i {
  line-height: 17px;
}

.widget-list .widget-list-content .widget-list-item {
  padding: 0px;
}

/* media queries */
@media (max-width: 1440px) {
  .col-xl-6 {
    width: 100%;
  }
}
</style>
